import React from "react";
import GatsbyLink from "../src/components/GatsbyLink";

export const CloseIcon = (props) => {
  return (
    <svg
      id="svg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="400"
      height="387.5354107648725"
      viewBox="0, 0, 400,387.5354107648725"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M359.884 17.598 C 350.269 19.797,350.741 19.363,273.160 97.333 C 210.784 160.022,209.529 161.205,203.979 162.609 C 196.834 164.416,194.511 162.755,171.190 139.177 C 95.547 62.698,58.081 25.867,51.672 21.683 C 36.888 12.032,20.224 20.037,23.417 35.258 C 24.959 42.608,27.527 46.132,42.225 61.067 C 48.740 67.686,61.744 80.985,71.124 90.619 C 80.504 100.254,104.369 124.213,124.157 143.863 C 167.026 186.431,168.425 187.998,168.885 193.944 C 169.448 201.209,169.083 201.609,89.335 281.202 C 28.075 342.342,28.182 342.230,25.382 348.147 C 19.429 360.729,27.674 373.710,40.324 371.673 C 48.639 370.334,54.684 365.635,76.142 343.829 C 114.270 305.081,185.774 234.045,189.995 230.721 C 196.326 225.734,201.239 224.785,206.789 227.476 C 209.797 228.934,201.596 220.837,295.543 315.109 C 344.065 363.799,346.428 366.018,353.585 369.622 C 366.631 376.192,377.495 369.221,376.613 354.845 C 375.947 343.996,371.083 336.915,354.077 322.038 C 347.482 316.268,348.625 317.402,318.749 287.016 C 304.496 272.519,279.650 247.403,263.536 231.202 C 230.746 198.234,231.779 199.438,231.787 194.221 C 231.796 188.091,229.157 190.953,303.287 116.667 C 354.379 65.467,369.690 49.908,371.259 47.590 C 382.688 30.713,376.290 13.846,359.884 17.598 "
          stroke="none"
          fill="#141C21"
          fillRule="evenodd"
        ></path>
        <path
          id="path1"
          d="M0.000 193.798 L 0.000 387.597 200.000 387.597 L 400.000 387.597 400.000 193.798 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 193.798 M370.206 18.465 C 379.007 22.415,379.479 35.452,371.259 47.590 C 369.690 49.908,354.379 65.467,303.287 116.667 C 229.157 190.953,231.796 188.091,231.787 194.221 C 231.779 199.438,230.746 198.234,263.536 231.202 C 279.650 247.403,304.496 272.519,318.749 287.016 C 348.625 317.402,347.482 316.268,354.077 322.038 C 371.083 336.915,375.947 343.996,376.613 354.845 C 377.495 369.221,366.631 376.192,353.585 369.622 C 346.428 366.018,344.065 363.799,295.543 315.109 C 201.596 220.837,209.797 228.934,206.789 227.476 C 201.239 224.785,196.326 225.734,189.995 230.721 C 185.774 234.045,114.270 305.081,76.142 343.829 C 54.684 365.635,48.639 370.334,40.324 371.673 C 27.674 373.710,19.429 360.729,25.382 348.147 C 28.182 342.230,28.075 342.342,89.335 281.202 C 169.083 201.609,169.448 201.209,168.885 193.944 C 168.425 187.998,167.026 186.431,124.157 143.863 C 104.369 124.213,80.504 100.254,71.124 90.619 C 61.744 80.985,48.740 67.686,42.225 61.067 C 27.527 46.132,24.959 42.608,23.417 35.258 C 20.224 20.037,36.888 12.032,51.672 21.683 C 58.081 25.867,95.547 62.698,171.190 139.177 C 194.511 162.755,196.834 164.416,203.979 162.609 C 209.529 161.205,210.784 160.022,273.160 97.333 C 328.724 41.491,345.328 25.191,349.736 22.157 C 356.846 17.265,364.468 15.890,370.206 18.465 "
          stroke="none"
          fill="none"
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};
