import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import "./Footer.scss";
import { Logo } from "../../../theme/Logo";
import GatsbyLink from "../GatsbyLink";
import WAPCLogo from "../../images/footer-icon-WAPC.png";
import ARCLogo from "../../images/footer-icon-ARC.png";
import AUDRCLogo from "../../images/footer-icon-AUDRC.png";
import DPLHLogo from "../../images/footer-icon-DPLH.png";
import UwaLogo from "../../images/footer-icon-UWA.png";

const Footer = ({
  data: {
    siteSettings: {
      options: { locations },
    },
    wordpressWpSettings,
    legalNavigation: { items: legalNav },
  },
  location,
}) => {
  const [isHomePage, setHomePage] = useState(false);

  useEffect(() => {
    location.pathname === "/" ? setHomePage(true) : setHomePage(false);
  }, [location]);
  return (
    <footer className="footer">
      <div className="wrap">
        <Logo />
        <div className="contact-method">
          <span>Phone</span>
          <a
            href={`tel:${locations[0].phone.replace(/[^A-Z0-9]/gi, "")}`}
            className="method"
          >
            {locations[0].phone}
          </a>
        </div>

        <div className="contact-method">
          <span>Address</span>
          <address className="method">{locations[0].addressDisplay}</address>
        </div>
        <div className="logos">
          <img src={AUDRCLogo} />
          <img src={UwaLogo} />
          <img src={ARCLogo} className="small" />
          <img src={WAPCLogo} />
          <img src={DPLHLogo} className="small" />
        </div>

        <ul className="legal-navigation">
          <li>
            ©{new Date().getFullYear()} - {wordpressWpSettings.title}
          </li>
          {Object.keys(legalNav).length > 0 &&
            legalNav?.map((navItem, index) => {
              return (
                <li key={index}>
                  <GatsbyLink to={navItem.url} decode={true}>
                    {navItem.title}
                  </GatsbyLink>
                </li>
              );
            })}
          <li>
            {isHomePage ? (
              <span className="distl">
                Website by{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://distl.com.au/"
                >
                  Distl
                </a>
              </span>
            ) : (
              <span className="distl">Website by Distl</span>
            )}
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              phone
            }
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);
