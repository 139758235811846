import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import "./SocialLinks.scss";
class PassSocialLinks extends Component {
  render() {
    const { data } = this.props;
    const { siteSettings } = data;
    const { socialLinks } = siteSettings.options;
    return (
      <div className="social-links">
        <ul>
          {socialLinks &&
            socialLinks.map((item, index) => (
              <li key={index}>
                <a
                  className={item.type}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export const SocialLinks = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      // query={graphql`
      //   query {
      //     siteSettings: wordpressAcfOptions(options: {}) {
      //       options {
      //         socialLinks {
      //           type
      //           url
      //         }
      //       }
      //     }
      //   }
      // `}
      render={(data) => <PassSocialLinks {...props} data={data} />}
    />
  );
};
