import React, { useState, useEffect, useRef, useCallback } from "react";
import lottieAnimation from "./animation.json";
import GatsbyLink from "../../GatsbyLink";
import Lottie from "react-lottie-player";

export const AnimatedLogo = () => {
  const el = useRef(null);

  const [play, setPlay] = useState(false);
  const [goTo, setGoTo] = useState(0);

  const mouseEnter = useCallback(() => {
    setPlay(true);
  }, []);

  useEffect(() => {
    if (el.current) {
      const animationEl = el.current;
      animationEl.addEventListener("mouseenter", mouseEnter);
      return () => {
        if (animationEl) {
          animationEl.removeEventListener("mouseenter", mouseEnter);
        }
      };
    }
  }, [mouseEnter]);

  return (
    <GatsbyLink to="/" className="logo">
      <div ref={el}>
        <Lottie
          play={play}
          loop={false}
          goTo={goTo}
          animationData={lottieAnimation}
          style={{
            width: "250px",
            height: "60px",
          }}
          onComplete={() => setPlay(false)}
          onLoopComplete={() => setPlay(false)}
        />
      </div>
    </GatsbyLink>
  );
};
